<template>
  <div class="line_wrapper">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
export default {
  name: 'TricolorLine'
}
</script>

<style scoped lang="scss">
.line_wrapper{
  display: flex;

  span {
    height: 0.13rem;
    border-radius: 0.13rem;

    &:nth-child(1) {
      width: 1.875rem;
      background-color: #246CB7;
    }
    &:nth-child(2) {
      width: 1.25rem;
      background-color: red;
    }
    &:nth-child(3) {
      flex: 1;
      background-color: #D5D6D9;
    }
    &:not(:nth-child(1)) {
      margin-left: 0.625rem;
    }
  }
}
</style>
